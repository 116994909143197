import React, { useState, useEffect, useContext } from 'react'
import { GlobalContext } from '../../context/GlobalState'
import axios from 'axios'
import Loader from './Loader'
import SingleSteamBath from './SingleSteamBath'

const SteamBath = () => {
  const { openDetailsView } = useContext(GlobalContext)

  const [loading, setLoading] = useState(true)
  const [steambaths, setSteamBaths] = useState([])

  const fetchSteamBaths = async () => {
    const res = await axios('https://energie.haffhus.de/api/v1/steambaths')
    //res.data.sort((a, b) => { return b.value - a.value })
    setSteamBaths(res.data)
  }

  useEffect(() => {
    const initLoop = async () => {
      await fetchSteamBaths()
      setTimeout(() => {
        setLoading(false)
      }, 1500)
      setInterval(fetchSteamBaths, 10000)
    }

    initLoop()

    return () => {
      clearInterval(initLoop)
    }
  }, [])

  return (
    <div
      className={`grid steambath`}
      style={!openDetailsView ? { WebkitOverflowScrolling: 'touch' } : {}}
    >
      {loading ? (
        <Loader />
      ) : (
        steambaths.map((steambath, i) => <SingleSteamBath steambath={steambath} key={i} />)
      )}
    </div>
  )
}

export default SteamBath
