import React, { useState } from 'react'
import DetailedView from '../../widgets/DetailedView'

const SingleHeater = ({ heater, show, shown }) => {
  const [showDetails, setShowDetails] = useState(false)

  const closeDetails = () => {
    setShowDetails(false)
  }

  return (
    <>
      <div
        onClick={() => setShowDetails(!showDetails)}
        className={`grid--elem ${show ? 'show' : ''} ${shown ? 'shown' : ''} ${
          heater.value > 0 ? 'active' : ''
        }`}
      >
        <span className={`name`}>{heater.name}</span>
        <div className={`value`}>
          <span>{heater.value}</span>
        </div>
        <span className={`unit`} title="Einheit">
          kW
        </span>
      </div>

      {showDetails ? (
        <DetailedView
          showDetails={showDetails}
          obj={heater}
          route={`heaters`}
          unit="kW"
          label="Verbrauch"
          produced={true}
          close={closeDetails}
        />
      ) : (
        ''
      )}
    </>
  )
}

export default SingleHeater
