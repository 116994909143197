import React from 'react'
import PropTypes from 'prop-types'

const PoolCover = (props) => {
  return (
    <svg
      id="PoolCover"
      className={`${props.className ? props.className : ''} ${
        props.covered ? 'covered' : 'closed'
      }`}
      viewBox="0 0 496.629 496.629"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>
        {props.title || 'Die Außentüren des Außenpools'} sind derzeit{' '}
        {props.covered ? 'geschlossen' : 'geöffnet'}
      </title>
      <g>
        <g className={`doors`}>
          {props.covered ? (
            <polygon points="458.092,44.38 458.092,23.365 40.537,23.365 40.537,44.38 0,22.105 0,476.523 80.034,444.996 80.034,66.085    70.852,61.04 70.852,53.682 427.777,53.682 427.777,61.04 418.596,66.085 418.596,444.996 498.629,476.523 498.629,22.105"></polygon>
          ) : (
            <path d="M0,21.479v451.5h34v-82.953h9.99v82.953h196.598V65.78H43.99v55.652H34V55.479h426.459v65.953h-9.988V65.78H253.873   v407.199h196.598v-82.953h9.988v82.953h34v-451.5H0z M198.105,243.021c7.018,0,12.709,5.688,12.709,12.708   s-5.691,12.708-12.709,12.708s-12.709-5.688-12.709-12.708S191.087,243.021,198.105,243.021z M43.99,168.453v174.55H34v-174.55   H43.99z M296.355,268.438c-7.019,0-12.709-5.688-12.709-12.708s5.69-12.708,12.709-12.708c7.018,0,12.709,5.688,12.709,12.708   S303.373,268.438,296.355,268.438z M450.471,343.003v-174.55h9.986v174.55H450.471z"></path>
          )}
        </g>
      </g>
    </svg>
  )
}

PoolCover.propTypes = {
  covered: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string
}

PoolCover.defaultProps = {
  covered: false
}

export { PoolCover }
