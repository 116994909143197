import React from 'react'
import { Spinner } from '../../widgets/Spinner'

const Loader = (props) => {
  return (
    <>
      <LoaderItem {...props} />
      <LoaderItem {...props} />
    </>
  )
}

const LoaderItem = (props) => {
  return (
    <div
      className={`grid--elem loading ${props.className ? props.className : ''} ${
        props.hide ? 'hide' : ''
      }`}
    >
      <span className={`name`}>Name</span>
      <div className={`value`}>
        <span>
          <Spinner />
        </span>
      </div>
      <span className={`unit`} title="Einheit">
        kW
      </span>
    </div>
  )
}

export default Loader
