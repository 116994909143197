import React, { useState, useEffect, useContext } from 'react'
import { GlobalContext } from '../../context/GlobalState'
import axios from 'axios'
import Loader from './Loader'
import SingleSauna from './SingleSauna'

const Sauna = () => {
  const { openDetailsView } = useContext(GlobalContext)

  const [loading, setLoading] = useState(true)
  const [saunas, setSaunas] = useState([])

  const fetchSaunas = async () => {
    const res = await axios('https://energie.haffhus.de/api/v1/saunas')
    //res.data.sort((a, b) => { return b.value - a.value })
    setSaunas(res.data)
  }

  useEffect(() => {
    const initLoop = async () => {
      await fetchSaunas()
      setTimeout(() => {
        setLoading(false)
      }, 1500)
      setInterval(fetchSaunas, 10000)
    }

    initLoop()

    return () => {
      clearInterval(initLoop)
    }
  }, [])

  return (
    <div
      className={`grid sauna`}
      style={!openDetailsView ? { WebkitOverflowScrolling: 'touch' } : {}}
    >
      {loading ? <Loader /> : saunas.map((sauna, i) => <SingleSauna sauna={sauna} key={i} />)}
    </div>
  )
}

export default Sauna
