import React, { useState } from 'react'
import DetailedView from '../../widgets/DetailedView'

const SingleMangle = ({ mangle }) => {
  const [showDetails, setShowDetails] = useState(false)

  const closeDetails = () => {
    setShowDetails(false)
  }

  return (
    <>
      <div
        onClick={() => setShowDetails(!showDetails)}
        className={`grid--elem ${mangle.value > 0 ? 'active' : ''}`}
      >
        <span className={`name`}>{mangle.name}</span>
        <div className={`value`}>
          <span>{mangle.value}</span>
        </div>
        <span className={`unit`} title="Einheit">
          kW
        </span>
      </div>

      {showDetails ? (
        <DetailedView
          showDetails={showDetails}
          obj={mangle}
          route={`mangles`}
          unit="kW"
          label="Verbrauch"
          produced={true}
          close={closeDetails}
        />
      ) : (
        ''
      )}
    </>
  )
}

export default SingleMangle
