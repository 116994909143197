import React, { useState, useEffect, useContext } from 'react'
import { GlobalContext } from '../../context/GlobalState'
import axios from 'axios'
import Loader from './Loader'
import SingleMangle from './SingleMangle'

const Mangle = () => {
  const { openDetailsView } = useContext(GlobalContext)

  const [loading, setLoading] = useState(true)
  const [mangles, setMangles] = useState([])

  const fetchMangles = async () => {
    const res = await axios('https://energie.haffhus.de/api/v1/mangles')
    //res.data.sort((a, b) => { return b.value - a.value })
    setMangles(res.data)
  }

  useEffect(() => {
    const initLoop = async () => {
      await fetchMangles()
      setTimeout(() => {
        setLoading(false)
      }, 1500)
      setInterval(fetchMangles, 10000)
    }

    initLoop()

    return () => {
      clearInterval(initLoop)
    }
  }, [])

  return (
    <div
      className={`grid mangle`}
      style={!openDetailsView ? { WebkitOverflowScrolling: 'touch' } : {}}
    >
      {loading ? <Loader /> : mangles.map((mangle, i) => <SingleMangle mangle={mangle} key={i} />)}
    </div>
  )
}

export default Mangle
