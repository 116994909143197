import React, { useState, useEffect, useContext } from 'react'
import { GlobalContext } from '../../context/GlobalState'
import axios from 'axios'
import Loader from './Loader'
import SingleWM from './SingleWM'

const WM = () => {
  const { openDetailsView } = useContext(GlobalContext)

  const [loading, setLoading] = useState(true)
  const [wms, setWMs] = useState([])

  const fetchWMs = async () => {
    const res = await axios('https://energie.haffhus.de/api/v1/washingmachines')
    //res.data.sort((a, b) => { return b.value - a.value })
    setWMs(res.data)
  }

  useEffect(() => {
    const initLoop = async () => {
      await fetchWMs()
      setTimeout(() => {
        setLoading(false)
      }, 1500)
      setInterval(fetchWMs, 10000)
    }

    initLoop()

    return () => {
      clearInterval(initLoop)
    }
  }, [])

  return (
    <div className={`grid wm`} style={!openDetailsView ? { WebkitOverflowScrolling: 'touch' } : {}}>
      {loading ? <Loader /> : wms.map((wm, i) => <SingleWM wm={wm} key={i} />)}
    </div>
  )
}

export default WM
