import React, { useState, useEffect, useContext } from 'react'
import { GlobalContext } from '../../context/GlobalState'
import axios from 'axios'
import Loader from './Loader'
import SingleHeater from './SingleHeater'

const Heater = () => {
  const { openDetailsView } = useContext(GlobalContext)

  const [show, setShow] = useState(0)
  const [loading, setLoading] = useState(true)
  const [heaters, setHeaters] = useState([])

  const fetchHeaters = async () => {
    const res = await axios('https://energie.haffhus.de/api/v1/heaters')
    //res.data.sort((a, b) => { return b.value - a.value })
    setHeaters(res.data)
    return res
  }

  const initFade = (arr) => {
    let cur = show
    let length = arr.length - 1

    setInterval(() => {
      if (cur >= length) {
        cur = 0
        setShow(cur)
      } else {
        cur++
        setShow(cur)
      }
    }, 5000)
  }

  useEffect(() => {
    const initLoop = async () => {
      const heaters = await fetchHeaters()
      setTimeout(() => {
        setLoading(false)
      }, 1500)
      setInterval(fetchHeaters, 10000)
      initFade(heaters.data)
    }

    initLoop()

    return () => {
      clearInterval(initLoop)
      clearInterval(initFade)
    }
  }, [])

  return (
    <div className={`heaters`} style={!openDetailsView ? { WebkitOverflowScrolling: 'touch' } : {}}>
      {loading ? (
        <Loader />
      ) : (
        heaters.map((heater, i) => (
          <SingleHeater heater={heater} key={i} show={show === i} shown={show >= i} />
        ))
      )}
    </div>
  )
}

export default Heater
