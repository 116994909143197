import React, { useState } from 'react'
import DetailedView from '../../widgets/DetailedView'
import { ReactComponent as WirelaneLogo } from '../../assets/icons/wirelane.svg'
import styles from './styles.module.scss'

const SingleCS = ({ cs }) => {
  const [showDetails, setShowDetails] = useState(false)

  const closeDetails = () => {
    setShowDetails(false)
  }

  return (
    <>
      <div
        onClick={() => setShowDetails(!cs.disconnected && !showDetails)}
        className={`grid--elem ${cs.value > 0 ? 'active' : ''} ${
          cs.disconnected ? 'disconnected' : ''
        }`}
      >
        <span className={[`name`, styles.name].join(' ')}>
          {cs.disconnected && (
            <a
              href="https://www.wirelane.com/de/loesungen/hotellerie"
              target="_blank"
              rel="noreferrer"
            >
              <WirelaneLogo />
            </a>
          )}
          {cs.name}
          {cs.public && <span className={[`public`, styles.public].join(' ')}>öffentlich</span>}
        </span>
        <div className={`value`}>
          <span>{cs.value}</span>
        </div>
        <span className={`unit`} title="Einheit">
          kW
        </span>
        <svg
          className={`status ${cs.status ? 'active' : ''}`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <g>
            <path d="M384,64h-42.667V10.68c0-5.903-4.063-10.68-9.958-10.68H181.333c-5.896,0-10.667,4.777-10.667,10.68V64H128c-23.531,0-42.667,18.62-42.667,42.18v363.103C85.333,492.842,104.469,512,128,512h256c23.531,0,42.667-19.158,42.667-42.718V106.18C426.667,82.62,407.531,64,384,64z M339.75,272.26l-85.333,138.667c-1.979,3.208-5.448,5.073-9.083,5.073c-0.969,0-1.938-0.135-2.906-0.406c-4.594-1.292-7.76-5.49-7.76-10.26V320h-53.333c-3.865,0-7.427-2.094-9.313-5.458c-1.885-3.375-1.802-7.51,0.229-10.802l85.333-138.667c2.5-4.063,7.406-5.927,11.99-4.667c4.594,1.292,7.76,5.49,7.76,10.26V256h53.333c3.865,0,7.427,2.094,9.313,5.458C341.865,264.833,341.781,268.969,339.75,272.26z" />
          </g>
        </svg>
      </div>

      {showDetails ? (
        <DetailedView
          showDetails={showDetails}
          obj={cs}
          route={`chargingstations`}
          unit="kW"
          label="Verbrauch"
          produced={true}
          close={closeDetails}
        />
      ) : (
        ''
      )}
    </>
  )
}

export default SingleCS
