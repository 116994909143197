import React, { useState, useEffect, useContext } from 'react'
import { GlobalContext } from '../../context/GlobalState'
import axios from 'axios'
import Loader from './Loader'
import SingleDryer from './SingleDryer'

const Dryer = () => {
  const { openDetailsView } = useContext(GlobalContext)

  const [loading, setLoading] = useState(true)
  const [dryers, setDryers] = useState([])

  const fetchDryers = async () => {
    const res = await axios('https://energie.haffhus.de/api/v1/dryers')
    //res.data.sort((a, b) => { return b.value - a.value })
    setDryers(res.data)
  }

  useEffect(() => {
    const initLoop = async () => {
      await fetchDryers()
      setTimeout(() => {
        setLoading(false)
      }, 1500)
      setInterval(fetchDryers, 10000)
    }

    initLoop()

    return () => {
      clearInterval(initLoop)
    }
  }, [])

  return (
    <div
      className={`grid dryer`}
      style={!openDetailsView ? { WebkitOverflowScrolling: 'touch' } : {}}
    >
      {loading ? <Loader /> : dryers.map((dryer, i) => <SingleDryer dryer={dryer} key={i} />)}
    </div>
  )
}

export default Dryer
