import React from 'react'
import Battery from '../../components/BatteryStorage/Battery'
import BHKW from '../../components/BHKW/BHKW'
import Total from '../../components/Total/Total'
import PV from '../../components/Photovoltaik/PV'
import Charging from '../../components/ChargingStation/ChargingStation'
import HeatEnergy from '../../components/HeatEnergy/HeatEnergy'
// import CO2 from '../../components/CO2/CO2'
import Pool from '../../components/Pool/Pool'
import Sauna from '../../components/Sauna/Sauna'
import WM from '../../components/WashingMachine/WM'
import Dryer from '../../components/Dryer/Dryer'

const Screen = () => {
  return (
    <div className={`screen content`}>
      <div className={`screen__content`}>
        <div className={`block multi`}>
          <Battery />
          <Total />
        </div>
        <div className={`block`}>
          <BHKW />
        </div>
        <div className={`block`}>
          <PV />
        </div>
        <div className={`block`}>
          <HeatEnergy />
        </div>
        <div className={`block`}>
          <Charging />
        </div>
        <div className={`block multi custom`}>
          <Pool />
          <Dryer />
          <Sauna />
          <WM />
        </div>
      </div>
    </div>
  )
}

export default Screen
